

let params = new URLSearchParams(window.location.search);

if (params.has("dark")) {
    window.localStorage.setItem("color_scheme", "dark");
} else if (params.has("light")) {
    window.localStorage.setItem("color_scheme", "light");
} else {
    let savedColorScheme = window.localStorage.getItem("color_scheme");

    if (!savedColorScheme) {
        const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
        window.localStorage.setItem("color_scheme", prefersDarkScheme.matches ? "dark" : "light");
    }
}

let colorScheme = window.localStorage.getItem("color_scheme") || "light";

if (colorScheme === "light") {
    import("../scss/light.scss");
} else {
    import("../scss/dark.scss");
}

document.addEventListener("DOMContentLoaded", function () {

    let colorSwitchHtml;
    if (colorScheme === "dark") {
        colorSwitchHtml = '<a href="?light" class="light-switch" title="Use light color scheme"><i class="bi bi-brightness-high"></i></a>';
    } else {
        colorSwitchHtml = '<a href="?dark" class="light-switch" title="Use dark color scheme"><i class="bi bi-moon-fill"></i></a>';
    }

    document.getElementById("light-switch-cnt").innerHTML = colorSwitchHtml;
});
